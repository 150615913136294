<template>
  <div class="traveler-profile-display-container">
    <div class="range-input-table">
      <p>{{ $t("label.travelerProfile.comfort") }}</p>
      <input type="range" min="0" max="10" v-model="comfort" />
      <p>{{ $t("label.travelerProfile.simplicity") }}</p>
      <p>{{ $t("label.travelerProfile.regular") }}</p>
      <input type="range" min="0" max="10" v-model="regularity" />
      <p>{{ $t("label.travelerProfile.beginning") }}</p>
      <p>{{ $t("label.travelerProfile.unusualExperiences") }}</p>
      <input type="range" min="0" max="10" v-model="unusual" />
      <p>{{ $t("label.travelerProfile.wellKnown") }}</p>
      <p>{{ $t("label.travelerProfile.basketball") }}</p>
      <input type="range" min="0" max="10" v-model="sportCulture" />
      <p>{{ $t("label.travelerProfile.unseenPerformance") }}</p>
      <p>{{ $t("label.travelerProfile.discoveries") }}</p>
      <input type="range" min="0" max="10" v-model="discovery" />
      <p>{{ $t("label.travelerProfile.beachLaze") }}</p>
      <p>{{ $t("label.travelerProfile.culture") }}</p>
      <input type="range" min="0" max="10" v-model="culturalActivity" />
      <p>{{ $t("label.travelerProfile.hobbies") }}</p>
      <p>{{ $t("label.travelerProfile.sport") }}</p>
      <input type="range" min="0" max="10" v-model="sportRelax" />
      <p>{{ $t("label.travelerProfile.laze") }}</p>
      <p>{{ $t("label.travelerProfile.mostSee") }}</p>
      <input type="range" min="0" max="10" v-model="pace" />
      <p>{{ $t("label.travelerProfile.takeTime") }}</p>
      <p>{{ $t("label.travelerProfile.culinaryExperiences") }}</p>
      <input type="range" min="0" max="10" v-model="food" />
      <p>{{ $t("label.travelerProfile.lightHealthy") }}</p>
      <p>{{ $t("label.travelerProfile.party") }}</p>
      <input type="range" min="0" max="10" v-model="party" />
      <p>{{ $t("label.travelerProfile.earlyBed") }}</p>
      <p>{{ $t("label.travelerProfile.shopping") }}</p>
      <input type="range" min="0" max="10" v-model="expenses" />
      <p>{{ $t("label.travelerProfile.noShopping") }}</p>
    </div>
    <div class="other-input">
      <span>{{ $t("label.travelerProfile.music") }}</span>
      <div class="other-input-block">
        <div class="list-template">
          <div
            v-for="(music, index) in musicList"
            :key="music.id"
            class="diet-specification-display"
          >
            <span>{{ music }}</span>
            <button @click="musicList.splice(index, 1)">x</button>
          </div>
          <br />
          <input
            type="text"
            @keypress.enter="
              () => {
                musicList.push(musicInput);
                musicInput = '';
              }
            "
            v-model="musicInput"
          />
        </div>
        <div class="suggestion-template">
          <span
            v-for="(musicsugg, index) in musicDefault"
            :key="musicsugg.id"
            @click="insertMusic(musicsugg, index)"
            >{{ musicsugg }}</span
          >
        </div>
      </div>
      <span>{{ $t("label.travelerProfile.licence") }}</span>
      <div class="other-input-block">
        <div class="list-template">
          <div
            v-for="(licence, index) in licenceList"
            :key="licence.id"
            class="diet-specification-display"
          >
            <span>{{ licence }}</span>
            <button @click="licenceList.splice(index, 1)">x</button>
          </div>
          <br />
          <input
            type="text"
            @keypress.enter="
              () => {
                licenceList.push(licenceInput);
                licenceInput = '';
              }
            "
            v-model="licenceInput"
          />
        </div>
        <div class="suggestion-template">
          <span
            v-for="(licenceSugg, index) in licenceDefault"
            :key="licenceSugg.id"
            @click="insertLicence(licenceSugg, index)"
            >{{ licenceSugg }}</span
          >
        </div>
      </div>
      <div class="other-input-block">
        <label for="passion-input">{{
          $t("label.travelerProfile.passion")
        }}</label>
        <input type="text" id="passion-input" v-model="passion" />
        <br />
        <span>{{ $t("label.travelerProfile.onePassion") }}</span>
        <br />
      </div>
      <div class="other-input-block">
        <label for="sport-input">{{ $t("label.travelerProfile.sport") }}</label>
        <input type="text" id="sport-input" v-model="sport" />
        <br />
        <span>{{ $t("label.travelerProfile.oneSport") }}</span>
        <br />
      </div>
      <div class="other-input-block">
        <span>{{ $t("label.travelerProfile.specificDiet") }}</span>
        <div class="diet-specification-container">
          <div
            v-for="(specification, specificationIndex) in diet"
            :key="specification.id"
            class="diet-specification-display"
          >
            <span>{{ specification }}</span>
            <button type="button" @click="diet.splice(specificationIndex, 1)">
              x
            </button>
          </div>
          <input
            type="text"
            v-model="dietInput"
            @keypress.enter="addToDiet()"
            @blur="addToDiet()"
          />
        </div>
      </div>
    </div>
    <br />
    <div class="button-container">
      <button type="button" class="save-button" @click="saveInformation()">
        {{ $t("buttons.save") }}
      </button>
      <button
        type="button"
        class="back-button"
        v-if="routeCheck"
        @click="getBack()"
      >
        {{ $t("buttons.back") }}
      </button>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "TravelerProfileForm",
  props: {
    clientIdprop: { type: String },
    comfortprop: { type: [String, Number], default: "5" },
    regularityprop: { type: [String, Number], default: "5" },
    unusualprop: { type: [String, Number], default: "5" },
    sportCultureprop: { type: [String, Number], default: "5" },
    discoveryprop: { type: [String, Number], default: "5" },
    culturalActivityprop: { type: [String, Number], default: "5" },
    sportRelaxprop: { type: [String, Number], default: "5" },
    paceprop: { type: [String, Number], default: "5" },
    foodprop: { type: [String, Number], default: "5" },
    partyprop: { type: [String, Number], default: "5" },
    expensesprop: { type: [String, Number], default: "5" },
    musicprop: { type: Array, default: () => [] },
    licenceprop: { type: Array, default: () => [] },
    passionprop: { type: String, default: "" },
    sportprop: { type: String, default: "" },
    dietprop: { type: Array, default: () => [] },
  },
  data() {
    return {
      clientId: this.clientIdprop || this.$store.getters.userId,
      comfort: this.comfortprop,
      regularity: this.regularityprop,
      unusual: this.unusualprop,
      sportCulture: this.sportCultureprop,
      discovery: this.discoveryprop,
      culturalActivity: this.culturalActivityprop,
      sportRelax: this.sportRelaxprop,
      pace: this.paceprop,
      food: this.foodprop,
      party: this.partyprop,
      expenses: this.expensesprop,
      musicList: this.musicprop,
      musicDefault: [
        this.$t("label.travelerProfile.classical"),
        this.$t("label.travelerProfile.pop"),
        this.$t("label.travelerProfile.rock"),
        this.$t("label.travelerProfile.jazz"),
      ].filter((x) => !this.musicprop.includes(x)),
      musicInput: "",
      licenceList: this.licenceprop,
      licenceDefault: [
        this.$t("label.travelerProfile.car"),
        this.$t("label.travelerProfile.motorbike"),
        this.$t("label.travelerProfile.boat"),
      ].filter((x) => !this.licenceprop.includes(x)),
      licenceInput: "",
      passion: this.passionprop,
      sport: this.sportprop,
      diet: this.dietprop,
      dietInput: "",
    };
  },
  computed: {
    routeCheck: function () {
      if (this.$route.params.id) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getBack() {
      this.$emit("getBack");
    },
    addToDiet() {
      if (this.dietInput.trim() != "") {
        this.diet.push(this.dietInput);
        this.dietInput = "";
      }
    },
    insertMusic: function (musicsugg, index) {
      this.musicList.push(musicsugg);
      this.musicDefault.splice(index, 1);
    },
    insertLicence: function (licencesugg, index) {
      this.licenceList.push(licencesugg);
      this.licenceDefault.splice(index, 1);
    },
    saveInformation: async function () {
      try {
        let res = null;
        let data = {
          clientId: this.clientId,
          comfort: this.comfort,
          regularity: this.regularity,
          unusual: this.unusual,
          sportCulture: this.sportCulture,
          discovery: this.discovery,
          culturalActivity: this.culturalActivity,
          sportRelax: this.sportRelax,
          pace: this.pace,
          food: this.food,
          party: this.party,
          expenses: this.expenses,
          music: this.musicList,
          licence: this.licenceList,
          passion: this.passion,
          sport: this.sport,
          diet: this.diet,
        };
        if (this.$route.params.id) {
          res = await HandleApi.putProfile(this.$route.params.id, data);
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.updated", {
              object: this.$t("notification.travelerProfile"),
            })
          );
          this.$router.go("/profile/" + res.data);
        } else {
          res = await HandleApi.postProfile(data);
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.created", {
              object: this.$t("notification.travelerProfile"),
            })
          );
          this.$router.push("/profile/" + res.data);
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    },
  },
};
</script>

<style scoped>
.traveler-profile-display-container {
  text-align: center;
}

@media only screen and (min-width: 601px) {
  .range-input-table {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: center;
    grid-column-gap: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .range-input-table {
    grid-template-columns: 1fr 100px 1fr;
    display: grid;
    justify-content: center;
    grid-column-gap: 10px;
  }
}
.range-input-table p {
  text-align: center;
}

.other-input {
  display: inline-block;
  margin: 10px auto;
}
.button-container {
  display: inline-block;
  margin: 10px auto;
}
.select-span {
  display: inline-block;
  padding: 5px;
  margin: 5px;
  background-color: lightgrey;
  border: 2px solid grey;
}

.select-span:hover {
  cursor: pointer;
}

.select-span-selected {
  display: inline-block;
  padding: 5px;
  margin: 5px;
  background-color: lightgrey;
  border: 2px solid red;
  color: red;
}

.select-span-selected:hover {
  cursor: pointer;
}
.other-input-block {
  margin: 10px 0;
}
.save-button {
  background-color: rgb(9, 201, 111);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.back-button {
  background-color: rgb(255, 72, 72);
  color: white;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

button:hover {
  cursor: pointer;
}

.diet-specification-container {
  display: inline-block;
}

.diet-specification-display {
  display: inline-block;
  margin-left: 10px;
  background-color: lightgrey;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.diet-specification-display button {
  background-color: lightgrey;
  color: grey;
  border: none;
}

.diet-specification-display button {
  background-color: lightgrey;
  color: grey;
  border: none;
}

.list-template {
  display: inline-block;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  width: 300px;
  margin-bottom: 20px;
}

.list-template input {
  margin-top: 10px;
  width: 280px;
  margin-left: 0;
}

.suggestion-template {
  display: inline-block;
  width: 300px;
  margin-bottom: 20px;
}

.suggestion-template span {
  border: 1px solid lightgrey;
  border-radius: 20px;
  padding: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.suggestion-template span:hover {
  cursor: pointer;
}
</style>
