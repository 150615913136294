<template>
  <div class="travel-display-container">
    <div class="traveler-profile-display-container" v-if="edit == false">
      <button
        type="button"
        @click="edit = !edit"
        class="edit-button"
        :style="{
          backgroundColor: $store.getters.color.color1,
          color: $store.getters.color.fontColor,
          borderColor: $store.getters.color.color1,
        }"
      >
        {{ $t("buttons.edit") }}
      </button>
      <div class="range-input-table">
        <p>{{ $t("label.travelerProfile.comfort") }}</p>
        <input
          type="range"
          min="0"
          max="10"
          v-model="comfort"
          disabled="true"
        />
        <p>{{ $t("label.travelerProfile.simplicity") }}</p>
        <p>{{ $t("label.travelerProfile.regular") }}</p>
        <input
          type="range"
          min="0"
          max="10"
          v-model="regularity"
          disabled="true"
        />
        <p>{{ $t("label.travelerProfile.beginning") }}</p>
        <p>{{ $t("label.travelerProfile.unusualExperiences") }}</p>
        <input
          type="range"
          min="0"
          max="10"
          v-model="unusual"
          disabled="true"
        />
        <p>{{ $t("label.travelerProfile.wellKnown") }}</p>
        <p>{{ $t("label.travelerProfile.basketball") }}</p>
        <input
          type="range"
          min="0"
          max="10"
          v-model="sportCulture"
          disabled="true"
        />
        <p>{{ $t("label.travelerProfile.unseenPerformance") }}</p>
        <p>{{ $t("label.travelerProfile.discoveries") }}</p>
        <input
          type="range"
          min="0"
          max="10"
          v-model="discovery"
          disabled="true"
        />
        <p>{{ $t("label.travelerProfile.beachLaze") }}</p>
        <p>{{ $t("label.travelerProfile.culture") }}</p>
        <input
          type="range"
          min="0"
          max="10"
          v-model="culturalActivity"
          disabled="true"
        />
        <p>{{ $t("label.travelerProfile.hobbies") }}</p>
        <p>{{ $t("label.travelerProfile.sport") }}</p>
        <input
          type="range"
          min="0"
          max="10"
          v-model="sportRelax"
          disabled="true"
        />
        <p>{{ $t("label.travelerProfile.laze") }}</p>
        <p>{{ $t("label.travelerProfile.mostSee") }}</p>
        <input type="range" min="0" max="10" v-model="pace" disabled="true" />
        <p>{{ $t("label.travelerProfile.takeTime") }}</p>
        <p>{{ $t("label.travelerProfile.culinaryExperiences") }}</p>
        <input type="range" min="0" max="10" v-model="food" disabled="true" />
        <p>{{ $t("label.travelerProfile.lightHealthy") }}</p>
        <p>{{ $t("label.travelerProfile.party") }}</p>
        <input type="range" min="0" max="10" v-model="party" disabled="true" />
        <p>{{ $t("label.travelerProfile.earlyBed") }}</p>
        <p>{{ $t("label.travelerProfile.shopping") }}</p>
        <input
          type="range"
          min="0"
          max="10"
          v-model="expenses"
          disabled="true"
        />
        <p>{{ $t("label.travelerProfile.noShopping") }}</p>
      </div>
      <div class="other-input">
        <span>{{ $t("label.travelerProfile.music") }}</span>
        <p v-for="(musicStyle, musicStyleIndex) in music" :key="musicStyle.id">
          {{
            musicStyleIndex != music.length - 1 ? musicStyle + " |" : musicStyle
          }}
        </p>
        <br />
        <span>{{ $t("label.travelerProfile.licence") }}</span>
        <p
          v-for="(licenceType, licenceTypeIndex) in licence"
          :key="licenceType.id"
        >
          {{
            licenceTypeIndex != licence.length - 1
              ? licenceType + " |"
              : licenceType
          }}
        </p>
        <br />
        <label for="passion-input">{{
          $t("label.travelerProfile.passion")
        }}</label>
        <p>{{ passion }}</p>
        <br />
        <label for="sport-input">{{ $t("label.travelerProfile.sport") }}</label>
        <p>{{ sport }}</p>
        <br />
        <span>{{ $t("label.travelerProfile.specificDiet") }}</span>
        <p
          v-for="(dietRestriction, dietRestrictionIndex) in diet"
          :key="dietRestriction.id"
        >
          {{
            dietRestrictionIndex != diet.length - 1
              ? dietRestriction + " |"
              : dietRestriction
          }}
        </p>
        <br />
      </div>
    </div>
    <travel-profile-form
      v-if="edit"
      :clientIdprop="clientId"
      :comfortprop="comfort"
      :regularityprop="regularity"
      :unusualprop="unusual"
      :sportCultureprop="sportCulture"
      :discoveryprop="discovery"
      :culturalActivityprop="culturalActivity"
      :sportRelaxprop="sportRelax"
      :paceprop="pace"
      :foodprop="food"
      :partyprop="party"
      :expensesprop="expenses"
      :musicprop="music"
      :licenceprop="licence"
      :passionprop="passion"
      :sportprop="sport"
      :dietprop="diet"
      @getBack="edit = false"
    ></travel-profile-form>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";
import TravelProfileForm from "./TravelerProfileForm.vue";

export default {
  name: "TravelerProfileDisplay",
  components: { TravelProfileForm },
  data() {
    return {
      edit: false,
      clientId: "",
      comfort: "5",
      regularity: "5",
      unusual: "5",
      sportCulture: "5",
      discovery: "5",
      culturalActivity: "5",
      sportRelax: "5",
      pace: "5",
      food: "5",
      party: "5",
      expenses: "5",
      music: [],
      licence: [],
      passion: "",
      sport: "",
      diet: [],
    };
  },
  methods: {
    getInformation: async function () {
      try {
        let travelerProfile = await HandleApi.getProfileInformation(
          this.$route.params.id
        );
        this.clientId = travelerProfile.clientId;
        this.comfort = travelerProfile.comfort;
        this.regularity = travelerProfile.regularity;
        this.sportCulture = travelerProfile.sportCulture;
        this.discovery = travelerProfile.discovery;
        this.culturalActivity = travelerProfile.culturalActivity;
        this.sportRelax = travelerProfile.sportRelax;
        this.pace = travelerProfile.pace;
        this.food = travelerProfile.food;
        this.party = travelerProfile.party;
        this.expenses = travelerProfile.expenses;
        this.passion = travelerProfile.passion;
        this.sport = travelerProfile.sport;
        this.unusual = travelerProfile.unusual;

        if (travelerProfile.hasOwnProperty("music")) {
          this.music = travelerProfile.music;
        }
        if (travelerProfile.hasOwnProperty("licence")) {
          this.licence = travelerProfile.licence;
        }
        if (travelerProfile.hasOwnProperty("diet")) {
          this.diet = travelerProfile.diet;
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    },
  },
  async created() {
    this.getInformation();
  },
};
</script>

<style scoped>
.edit-button {
  box-shadow: 2px 2px 2px lightgrey;
  color: white;
  border: 2px solid #00909f;
  font-size: 17px;
  background-color: #00909f;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 7px;
  margin: auto 0;
}

.edit-button:hover {
  border: 2px solid #034c57;
  color: #034c57;
}

.traveler-profile-display-container {
  text-align: center;
}

@media only screen and (min-width: 601px) {
  .range-input-table {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: center;
    grid-column-gap: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .range-input-table {
    grid-template-columns: 1fr 100px 1fr;
    display: grid;
    justify-content: center;
    grid-column-gap: 10px;
  }
}
.range-input-table p {
  text-align: center;
}
.other-input {
  display: inline-block;
  margin: 10px auto;
}
.button-container {
  display: inline-block;
  margin: 10px auto;
}
.select-span {
  display: inline-block;
  padding: 5px;
  margin: 5px;
  background-color: lightgrey;
  border: 2px solid grey;
}

.select-span:hover {
  cursor: pointer;
}

.select-span-selected {
  display: inline-block;
  padding: 5px;
  margin: 5px;
  background-color: lightgrey;
  border: 2px solid red;
  color: red;
}

.select-span-selected:hover {
  cursor: pointer;
}
.other-input-block {
  margin: 10px 0;
}

.other-input p {
  display: inline-block;
  margin-left: 10px;
}

.save-button {
  background-color: rgb(9, 201, 111);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.back-button {
  background-color: rgb(255, 72, 72);
  color: white;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

button:hover {
  cursor: pointer;
}

.diet-specification-container {
  display: inline-block;
}

.diet-specification-display {
  display: inline-block;
  margin-left: 10px;
  background-color: lightgrey;
  padding: 5px;
  border-radius: 10px;
}

.diet-specification-display button {
  background-color: lightgrey;
  color: grey;
  border: none;
}
</style>
